<template>
  <div>
    <!--begin::Row-->
    <div class="row">
        <div class="col-lg-12">
            <div class="card card-custom gutter-b">
                <!--begin::Header-->
                <div class="card-header">
                    <div class="card-title" style="text-align:center;">
                    <h3 class="card-label">Penugasan</h3>
                    </div>
                </div>
                <!--end::Header-->
                <!--begin::Body-->
                <div class="card-body pt-0">
                    <!--begin::Item-->
                    <div>
                        <!--begin::Content-->
                        <div>
                            <br>
                            <div class="form-group row">
                                    <!-- <label class="col-lg-2 col-form-label"></label> -->
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-xl-6">
                                                    <!--begin::Input-->
                                                    <div class="form-group" v-if="ticket.idpenerima != null">            
                                                        <label>Petugas</label>
                                                           <b-form-select disabled v-model="ticket.idpenerima" class="mb-3">
                                                                <b-form-select-option v-for="(value, name) in pengguna"  :key="name" :value="value.id">{{value.name}} </b-form-select-option>
                                                            </b-form-select>
                                                    </div>
                                                    <div class="form-group" v-else>
                                                        <label>Petugas</label>
                                                            <v-selectnih label="name" v-model="selectedpengguna"  :options="pengguna"/>
                                                    </div>
                                     <!--end::Input-->            
                                                </div>
                                                <div class="col-xl-6">
                                                    <!--begin::Input-->
                                                    <div class="form-group">
                                                        <label>Priority</label>
                                                         <b-form-select v-model="ticket.prioritas" :options="priority"></b-form-select>
                                                    </div>
                                        <!--end::Input-->
                                                </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <b-button @click="editpenugasan" style="background-color: #030A8C; float:right;" variant="primary">Kirim</b-button>
                                    <b-button @click="tutup" style="float: right; margin-right: 10px;" variant="danger">Selesai</b-button>
                                </div>
                        </div>
                        <!--end::Content-->
                    </div>
                    <!--end::Item-->
                </div>
                <!--end: Card Body-->
            </div>
            <!-- Tambah -->
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
                <!-- <div class="flex-row-fluid ml-lg-8" id="kt_chat_content"> -->
										<!--begin::Card-->
										<div class="card card-custom">
											<!--begin::Header-->
											<div class="card-header align-items-center px-4 py-3">
												<div class="text-left flex-grow-1">
													<!--begin::Aside Mobile Toggle-->
													<button type="button" class="btn btn-clean btn-sm btn-icon btn-icon-md d-lg-none" id="kt_app_chat_toggle">
														<span class="svg-icon svg-icon-lg">
															<!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Adress-book2.svg-->
															<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																	<rect x="0" y="0" width="24" height="24" />
																	<path d="M18,2 L20,2 C21.6568542,2 23,3.34314575 23,5 L23,19 C23,20.6568542 21.6568542,22 20,22 L18,22 L18,2 Z" fill="#000000" opacity="0.3" />
																	<path d="M5,2 L17,2 C18.6568542,2 20,3.34314575 20,5 L20,19 C20,20.6568542 18.6568542,22 17,22 L5,22 C4.44771525,22 4,21.5522847 4,21 L4,3 C4,2.44771525 4.44771525,2 5,2 Z M12,11 C13.1045695,11 14,10.1045695 14,9 C14,7.8954305 13.1045695,7 12,7 C10.8954305,7 10,7.8954305 10,9 C10,10.1045695 10.8954305,11 12,11 Z M7.00036205,16.4995035 C6.98863236,16.6619875 7.26484009,17 7.4041679,17 C11.463736,17 14.5228466,17 16.5815,17 C16.9988413,17 17.0053266,16.6221713 16.9988413,16.5 C16.8360465,13.4332455 14.6506758,12 11.9907452,12 C9.36772908,12 7.21569918,13.5165724 7.00036205,16.4995035 Z" fill="#000000" />
																</g>
															</svg>
															<!--end::Svg Icon-->
														</span>
													</button>
													<!--end::Aside Mobile Toggle-->
													<!--begin::Dropdown Menu-->
													
													<!--end::Dropdown Menu-->
												</div>
												<div class="text-center flex-grow-1">
													<div  class="text-dark-75 font-weight-bold font-size-h5">{{ticket.tentang}}</div>
													<div>
														<span class="font-weight-bold text-muted font-size-sm">{{ticket.jenis_pengaduan}}</span>
													</div>
												</div>
												<div class="text-right flex-grow-1">
													<!--begin::Dropdown Menu-->
													
													<!--end::Dropdown Menu-->
												</div>
											</div>
											<!--end::Header-->
											<!--begin::Body-->
											<div class="card-body">
												<!--begin::Scroll-->
												<div class="scroll scroll-pull" data-mobile-height="350">
													<!--begin::Messages-->
													<div  class="messages">
														<!--begin::Message In-->
                            <div v-for="(item, index) in percakapan" :key="index">
														<div v-if="item.instansi_pengirim === 'Helpdesk'" class="d-flex flex-column mb-5 align-items-start">
															<div class="d-flex align-items-center">
																<div class="symbol symbol-circle symbol-40 mr-3">
																	<!-- <img alt="Pic" src="assets/media/users/300_12.jpg" /> -->
                                  <span class="symbol-label font-size-h1 font-weight-bold">
                                    {{ item.pengirim.charAt(0).toUpperCase() }}
                                  </span>
																</div>
																<div>
																	<a href="#" class="text-dark-75 text-hover-primary font-weight-bold font-size-h6">{{item.pengirim}}</a><br>
																	<span class="text-muted font-size-sm">{{item.created_at | dateToString}}</span>
																	<!-- <span class="text-muted font-size-sm">2 Hours</span> -->
																</div>
															</div>
															<div class="mt-2 rounded p-5 bg-light-success text-dark-50 font-weight-bold font-size-lg text-left max-w-400px">
                                <div v-html="item.pesan"></div>
                                <div v-if="item.lampiran_1 != '' || item.lampiran_1 != null">
                                  <div v-if="cekEkstensi(item.lampiran_1) === 'jpeg' || cekEkstensi(item.lampiran_1) === 'jpg' || cekEkstensi(item.lampiran_1) === 'png'">
                                    <b-img :src="item.lampiran_1" style="height: 100px; width: 200px;" fluid alt="Foto"></b-img>
                                  </div>
                                  <div v-else-if="cekEkstensi(item.lampiran_1) === 'pdf' || cekEkstensi(item.lampiran_1) === 'doc' || cekEkstensi(item.lampiran_1) === 'docx'">
                                    <a :href="item.lampiran_1" target="_blank">
                                    <b-button
                                      variant="secondary"
                                      type="submit"
                                    >
                                      <feather-icon
                                        icon="FileTextIcon"
                                        class="mr-50"
                                      />
                                      <i data-feather='file'></i>
                                      <span class="align-middle">{{getNamaFile(item.lampiran_1)}}</span>
                                    </b-button>
                                    </a>
                                  </div>
                                </div>
                                <br>
                                <div v-if="item.lampiran_2 != '' || item.lampiran_2 != null">
                                  <div v-if="cekEkstensi(item.lampiran_2) === 'jpeg' || cekEkstensi(item.lampiran_2) === 'jpg' || cekEkstensi(item.lampiran_2) === 'png'">
                                    <b-img :src="item.lampiran_2" style="height: 100px; width: 200px;" fluid alt="Foto"></b-img>
                                  </div>
                                  <div v-else-if="cekEkstensi(item.lampiran_2) === 'pdf' || cekEkstensi(item.lampiran_2) === 'doc' || cekEkstensi(item.lampiran_2) === 'docx'">
                                    <a :href="item.lampiran_2" target="_blank">
                                    <b-button
                                      variant="secondary"
                                      type="submit"
                                    >
                                      <feather-icon
                                        icon="FileTextIcon"
                                        class="mr-50"
                                      />
                                      <i data-feather='file'></i>
                                      <span class="align-middle">{{getNamaFile(item.lampiran_2)}}</span>
                                    </b-button>
                                    </a>
                                  </div>
                                </div>
                                <br>
                                <div v-if="item.lampiran_3 != '' || item.lampiran_3 != null">
                                  <div v-if="cekEkstensi(item.lampiran_3) === 'jpeg' || cekEkstensi(item.lampiran_3) === 'jpg' || cekEkstensi(item.lampiran_3) === 'png'">
                                    <b-img :src="item.lampiran_3" style="height: 100px; width: 200px;" fluid alt="Foto"></b-img>
                                  </div>
                                  <div v-else-if="cekEkstensi(item.lampiran_3) === 'pdf' || cekEkstensi(item.lampiran_3) === 'doc' || cekEkstensi(item.lampiran_3) === 'docx'">
                                    <a :href="item.lampiran_3" target="_blank">
                                    <b-button
                                      variant="secondary"
                                      type="submit"
                                    >
                                      <feather-icon
                                        icon="FileTextIcon"
                                        class="mr-50"
                                      />
                                      <i data-feather='file'></i>
                                      <span class="align-middle">{{getNamaFile(item.lampiran_3)}}</span>
                                    </b-button>
                                    </a>
                                  </div>
                                </div>
                              </div>
														</div>
														<!--end::Message In-->
														<!--begin::Message Out-->
														<div v-else class="d-flex flex-column mb-5 align-items-end">
															<div class="d-flex align-items-center">
																<div>
																	<!-- <span class="text-muted font-size-sm">3 minutes</span> -->
																	<a href="#" class="text-dark-75 text-hover-primary font-weight-bold font-size-h6">{{item.pengirim}}</a><br>
																	<span class="text-muted font-size-sm">{{item.created_at | dateToString}}</span>
																</div>
																<div class="symbol symbol-circle symbol-40 ml-3">
																	<span class="symbol-label font-size-h1 font-weight-bold">
                                    {{ item.pengirim.charAt(0).toUpperCase() }}
                                  </span>
																</div>
															</div>
															<div class="mt-2 rounded p-5 bg-light-success text-dark-50 font-weight-bold font-size-lg text-left max-w-400px">
                                <div v-html="item.pesan"></div>
                                <div v-if="item.lampiran_1 != '' || item.lampiran_1 != null">
                                  <div v-if="cekEkstensi(item.lampiran_1) === 'jpeg' || cekEkstensi(item.lampiran_1) === 'jpg' || cekEkstensi(item.lampiran_1) === 'png'">
                                    <b-img :src="item.lampiran_1" style="height: 100px; width: 200px;" fluid alt="Foto"></b-img>
                                  </div>
                                  <div v-else-if="cekEkstensi(item.lampiran_1) === 'pdf' || cekEkstensi(item.lampiran_1) === 'doc' || cekEkstensi(item.lampiran_1) === 'docx'">
                                    <a :href="item.lampiran_1" target="_blank">
                                    <b-button
                                      variant="secondary"
                                      type="submit"
                                    >
                                      <feather-icon
                                        icon="FileTextIcon"
                                        class="mr-50"
                                      />
                                      <i data-feather='file'></i>
                                      <span class="align-middle">{{getNamaFile(item.lampiran_1)}}</span>
                                    </b-button>
                                    </a>
                                  </div>
                                </div>
                                <br>
                                <div v-if="item.lampiran_2 != '' || item.lampiran_2 != null">
                                  <div v-if="cekEkstensi(item.lampiran_2) === 'jpeg' || cekEkstensi(item.lampiran_2) === 'jpg' || cekEkstensi(item.lampiran_2) === 'png'">
                                    <b-img :src="item.lampiran_2" style="height: 100px; width: 200px;" fluid alt="Foto"></b-img>
                                  </div>
                                  <div v-else-if="cekEkstensi(item.lampiran_2) === 'pdf' || cekEkstensi(item.lampiran_2) === 'doc' || cekEkstensi(item.lampiran_2) === 'docx'">
                                    <a :href="item.lampiran_2" target="_blank">
                                    <b-button
                                      variant="secondary"
                                      type="submit"
                                    >
                                      <feather-icon
                                        icon="FileTextIcon"
                                        class="mr-50"
                                      />
                                      <i data-feather='file'></i>
                                      <span class="align-middle">{{getNamaFile(item.lampiran_2)}}</span>
                                    </b-button>
                                    </a>
                                  
                                  </div>
                                </div>
                                <br>
                                <div v-if="item.lampiran_3 != '' || item.lampiran_3 != null">
                                  <div v-if="cekEkstensi(item.lampiran_3) === 'jpeg' || cekEkstensi(item.lampiran_3) === 'jpg' || cekEkstensi(item.lampiran_3) === 'png'">
                                    <b-img :src="item.lampiran_3" style="height: 100px; width: 200px;" fluid alt="Foto"></b-img>
                                  </div>
                                  <div v-else-if="cekEkstensi(item.lampiran_3) === 'pdf' || cekEkstensi(item.lampiran_3) === 'doc' || cekEkstensi(item.lampiran_3) === 'docx'">
                                    <a :href="item.lampiran_3" target="_blank">
                                    <b-button
                                      variant="secondary"
                                      type="submit"
                                    >
                                      <feather-icon
                                        icon="FileTextIcon"
                                        class="mr-50"
                                      />
                                      <i data-feather='file'></i>
                                      <span class="align-middle">{{getNamaFile(item.lampiran_3)}}</span>
                                    </b-button>
                                    </a>
                                  
                                  </div>
                                </div>
                              </div>
														</div>
                            </div>
														<!--end::Message Out-->
													</div>
													<!--end::Messages-->
												</div>
												<!--end::Scroll-->
											</div>
											<!--end::Body-->
											<!--begin::Footer-->
											<!--end::Footer-->
										</div>
										<!--end::Card-->
									<!-- </div> -->
        </div>
    </div>
  </div>
</template>
<style>
  div.messages {
  /* background-color: lightblue; */
  /* width: 110px; */
  height: 500px;
  overflow: auto;
}
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from 'axios';
import { VueEditor } from "vue2-editor";
import vSelect from 'vue-select'

export default {
  data() {
      return {
        url: localStorage.getItem('baseapi'),
        token: localStorage.getItem('id_token'),
        test:'hai',
        user: JSON.parse(localStorage.getItem('user')),
        ticket:[],
        profil:[],
        search: '',
        pesan:'',
        tentang:'',
        selectedpengguna:'',
       selectedpengaduan: null,
       selectedpriority: null,
       priority: [
         { value: null, text: 'Silahkan Pilih' },
         { value: 'High', text: 'High' },
         { value: 'Medium', text: 'Medium' },
         { value: 'Low', text: 'Low' },
       ],
       selectedstatus: null,
       status: [
         { value: null, text: 'Silahkan Pilih' },
         { value: 'On Progress', text: 'On Progress' },
         { value: 'Closed', text: 'Closed' },
       ],
       lampiran_1:'',
       lampiran_2:'',
       lampiran_3:'',
       idtiket:'',
       pengguna:[],
       percakapan:[],
      }
  },
  components: { VueEditor,'v-selectnih':vSelect },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Open Ticket" }]);
    this.loaddata()
  },
  methods: {
    getNamaFile(input){
      if(input == null){

      } else {
        return input.split(/[/ ]+/).pop();
      }
      
    },
    cekEkstensi(input){
      if(input == null){

      } else {
        return input.split(/[. ]+/).pop();
      }
    },
    loaddata(){
      axios.get(this.url+"/tiket?id="+ this.$route.params.id, {
        headers: {
            'xth': this.token
        }
      })
      .then(response => {
          this.ticket = response.data.data;
      }).catch(error=>{
          return error
          });
            
      axios.get(this.url+"/users?filter=id_role,=,helpdesk", {
        headers: {
            'xth': this.token
        }
      })
      .then(response => {
          this.pengguna = response.data.data;
      }).catch(error=>{
          return error
          });

      axios.get(this.url+`/helpdesk?filter=idtiket,=,`+this.$route.params.id+`&sort=id desc`, {
        headers: {
            'xth': this.token
        }
      })
      .then(response => {
          this.percakapan = response.data.data;
      }).catch(error=>{
          return error
          });
    },
    editpenugasan(){
      axios
          .put(this.url+"/tiket/"+ this.$route.params.id, {
            idpenerima:this.selectedpengguna.id,
            penerima:this.selectedpengguna.name,
            prioritas:this.ticket.prioritas,
          },{
              headers:{
              "xth": this.token
              }
          })
        .then(response => {
          this.$bvToast.toast('Berhasil Disimpan', {
              title: `Success `,
              variant: `success`,
              solid: true
          })
          this.loaddata()
          return response
        })
          .catch(error=>{
            this.$bvToast.toast('Gagal Disimpan', {
                title: `Failed `,
                variant: `danger`,
                solid: true
            })
            console.log(error)
            return error
            });
    },

    tutup(){
      axios
          .put(this.url+"/tiket/"+ this.$route.params.id, {
            status:'Tutup',
          },{
                headers:{
                "xth": this.token
                }
            })
          .then(response => {
            this.$bvToast.toast('Berhasil Ditutup', {
                title: `Success `,
                variant: `success`,
                solid: true
            })
            this.loaddata()
            return response
          })
          .catch(error=>{
            this.$bvToast.toast('Gagal Ditutup', {
                title: `Failed `,
                variant: `danger`,
                solid: true
            })
            return error
            });
    },
    
    tambahpercakapan(){
        let formData = new FormData();
                formData.append('idtiket', this.idtiket);
                formData.append('pengirim', this.user.name);
                formData.append('instansi_pengirim', this.profil.nomenklatur_ptsp);
                formData.append('pesan', this.pesan);
                formData.append('lampiran_1', this.lampiran_1);
                formData.append('lampiran_2', this.lampiran_2);
                formData.append('lampiran_3', this.lampiran_3);
          axios
            .post(this.url+'/helpdesk', formData,{
              headers: {
                  "Content-type": "application/json",
                  "xth": this.token,
                  }
              }
            )
            .then(response => {
              this.$bvToast.toast('Berhasil Dikirim', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                })
            this.loaddata()
              return response
              
            })
            .catch(error=>{
            console.log(error)
            this.$bvToast.toast('Gagal Dikirim', {
            title: `Failed `,
            variant: `danger`,
            solid: true
            })
              return error
              })
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  },
  filters:{
      dateToString(value){
          let waktunya = value.split(" ");
          let temp = waktunya[0].split("-");
          let month = parseInt(temp[1]) - 1;
          const monthNames = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
          return temp[2] + " " + monthNames[month] + " " + temp[0];
      }
  },
};
</script>
